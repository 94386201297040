.notifications-container {
    max-height: 100vh;
    max-width: 320px;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 9999
}

.notifications-container.notify-is-x-center {
    left: 50%;
    transform: translateX(-50%)
}

.notifications-container.notify-is-y-center {
    top: 50%;
    transform: translateY(-50%)
}

.notifications-container.notify-is-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.notifications-container.notify-is-left {
    left: 0
}

.notifications-container.notify-is-right {
    right: 0
}

.notifications-container.notify-is-top {
    top: 0
}

.notifications-container.notify-is-bottom {
    bottom: 0
}

.notifications-container.notify-is-x-center.notify-is-top {
    top: var(--distance)
}

.notifications-container.notify-is-x-center.notify-is-bottom {
    bottom: var(--distance)
}

.notifications-container>* {
    pointer-events: auto
}

.notify {
    --notify-error: rgb(235, 87, 87);
    --notify-error-progress: rgb(192, 69, 69);
    --notify-success: rgb(111, 207, 151);
    --notify-success-progress: rgb(84, 170, 120);
    --notify-warning: rgb(242, 201, 76);
    --notify-warning-progress: rgb(196, 166, 79);
    --notify-info: rgb(81, 205, 243);
    --notify-info-progress: rgb(84, 169, 196);
    --notify-gray: rgb(51, 51, 51);
    --notify-gray-2: rgb(77, 77, 77);
    --notify-gray-3: rgb(130, 130, 130);
    --notify-white: rgb(255, 255, 255);
    --notify-white-2: rgba(255, 255, 255, 0.8);
    --notify-padding: 0.75rem;
    --notify-icon-size: 32px;
    --notify-close-icon-size: 16px;
    align-items: center;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow: hidden;
    padding: var(--notify-padding);
    position: relative;
    text-decoration: none;
    transition-timing-function: ease;
    width: 100%
}

.notify__icon {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: var(--notify-icon-size);
    justify-content: center;
    margin-right: 12px;
    width: var(--notify-icon-size)
}

.notify__close {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: var(--notify-close-icon-size);
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 12px;
    user-select: none;
    width: var(--notify-close-icon-size)
}

.notify__close * {
    pointer-events: none
}

.notify__title {
    font-size: 1rem;
    font-weight: 600;
    padding-right: calc(var(--notify-padding) + var(--notify-close-icon-size))
}

.notify__text {
    font-size: 0.875rem;
    margin-top: 0.25rem
}

.notify--type-1 {
    background-color: #fff;
}

.notify--type-1 .notify__close {
    color: var(--notify-gray-3)
}

.notify--type-1 .notify__title {
    color: var(--notify-gray)
}

.notify--type-1 .notify__text {
    color: var(--notify-gray-2)
}

.notify--type-2 {
    color: var(--notify-gray)
}

.notify--type-3 {
    color: var(--notify-white)
}

.notify--type-3 .notify__text {
    color: var(--notify-white-2)
}

.notify--error.notify--type-1 {
    box-shadow: 0 2px 26px rgba(215, 0, 0, 0.1);
    color: var(--notify-error)
}

.notify--error.notify--type-2,
.notify--error.notify--type-3 {
    background-color: var(--notify-error)
}

.notify--warning.notify--type-1 {
    box-shadow: 0 2px 26px rgba(242, 201, 76, 0.1);
    color: var(--notify-warning)
}

.notify--warning.notify--type-2,
.notify--warning.notify--type-3 {
    background-color: var(--notify-warning)
}

.notify--success.notify--type-1 {
    box-shadow: 0 2px 26px rgba(82, 215, 0, 0.1);
    color: var(--notify-success)
}

.notify--success.notify--type-2,
.notify--success.notify--type-3 {
    background-color: var(--notify-success)
}

.notify--info.notify--type-1 {
    box-shadow: 0 2px 26px rgba(84, 175, 202, 0.1);
    color: var(--notify-info)
}

.notify--info.notify--type-2,
.notify--info.notify--type-3 {
    background-color: var(--notify-info)
}

.notify--fade {
    opacity: 0;
    will-change: opacity
}

.notify--fadeIn {
    opacity: 1
}

.notify--slide {
    opacity: 0;
    will-change: opacity, transform
}

.notify-is-center .notify--slide,
.notify-is-y-center .notify--slide,
.notify-is-x-center:not(.notify-is-bottom) .notify--slide {
    transform: translateY(-20px)
}

.notify-is-x-center.notify-is-bottom .notify--slide {
    transform: translateY(20px)
}

.notify-is-right .notify--slide {
    transform: translateX(calc(var(--distance) + 110%))
}

.notify-is-left .notify--slide {
    transform: translateX(calc((var(--distance) * -1) - 110%))
}

.notify-is-x-center:not(.notify-is-bottom) .notify--slideIn,
.notify-is-center .notify--slideIn,
.notify-is-y-center .notify--slideIn,
.notify-is-x-center.notify-is-bottom .notify--slideIn {
    opacity: 1;
    transform: translateY(0)
}

.notify-is-right .notify--slideIn,
.notify-is-left .notify--slideIn {
    opacity: 1;
    transform: translateX(0)
}

.notify-is-left .notify {
    left: var(--distance)
}

.notify-is-right .notify {
    right: var(--distance)
}

.notify-is-top .notify,
.notify-is-center .notify,
.notify-is-y-center .notify,
.notify-is-x-center.notify-is-top .notify {
    margin-top: var(--gap)
}

.notify-is-bottom .notify,
.notify-is-x-center:not(.notify-is-top) .notify {
    margin-bottom: var(--gap)
}

.notify.notify-autoclose {
    --progress-height: 5px;
    padding-bottom: calc(var(--notify-padding) + var(--progress-height))
}

.notify.notify-autoclose::before {
    animation: progress calc(var(--timeout) * 1ms) linear forwards;
    bottom: 0;
    content: '';
    height: var(--progress-height);
    left: 0;
    position: absolute;
    transform: scale3d(1, 1, 1);
    transform-origin: left;
    width: 100%
}

@keyframes progress {
    to {
        transform: scale3d(0, 1, 1)
    }
}

.notify.notify-autoclose.notify--error::before {
    background-color: var(--notify-error-progress)
}

.notify.notify-autoclose.notify--warning::before {
    background-color: var(--notify-warning-progress)
}

.notify.notify-autoclose.notify--success::before {
    background-color: var(--notify-success-progress)
}

.notify.notify-autoclose.notify--info::before {
    background-color: var(--notify-info-progress)
}