.asColorPicker-dropdown {
    max-width: 26rem;
}

.asColorPicker-trigger {
    border: 0 none;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
    left: 0;
    right: auto;
}

.asColorPicker-clear {
    display: none;
    position: absolute;
    right: 1rem;
    text-decoration: none;
    top: .5rem;
}

.daterangepicker {
    td.active {
        background-color: var(--primary);
        &:hover {
            background-color: var(--primary);
        }
    }
    button.applyBtn {
        background-color: var(--primary);
        border-color: var(--primary);
    }
}

.datepicker {
    &.datepicker-dropdown {
        background: $l-bg;
        border-radius: 0.0625rem;
        border: 0.0625rem solid $gallery;
        td.day,
        th.next,
        th.prev {
            height: 1.875rem;
            width: 1.875rem !important;
            padding: 0;
            text-align: center;
            font-weight: 300;
            border-radius: 3.125rem;
            &:hover {
                @extend %gradient-9;
                box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
                color: $white;
            }
        }
        th.datepicker-switch,
        th.next,
        th.prev {
            font-weight: 300;
            color: $heading;
        }
        th.dow {
            font-weight: 300;
        }
    }
    table {
        tr td.selected,
        tr td.active.active {
            @extend %gradient-9;
            box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
            border: 0;
        }
        tr {
            td.today {
                @extend %gradient-9;
                box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
                color: #ffffff;
                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
                    color: #ffffff;
                }
            }
            td.today.disabled {
                @extend %gradient-9;
                box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
                color: #ffffff;
                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
                    color: #ffffff;
                }
            }
        }
    }
}

.picker {
    &__select--month,
    &__select--year {
        height: 2.5em;
    }
    &__input {
        background-color: transparent !important;
        @at-root [data-theme-version="dark"] & {
            background-color: transparent !important;
            border: 0.0625rem solid $d-border;
        }
    }
}

.asColorPicker-wrap {
    .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.MuiInput-underline:before {
    border-bottom: 1px solid #eee !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #eee !important;
}

.css-yk16xz-control {
    border-color: #eee !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickerDTTabs-tabs,
.MuiPickersDay-daySelected,
.MuiPickersDay-daySelected:hover {
    background-color: $primary !important;
}

.MuiPickersClockPointer-thumb {
    border: 14px solid $primary !important;
}

.MuiButton-textPrimary {
    color: $primary !important;
}