.contacts-search {
    width: 19.75rem;
    .form-control {
        border-color: #EEEEEE;
        border-right: 0;
        background: transparent;
        border-top-left-radius: 3.125rem;
        border-bottom-left-radius: 3.125rem;
        height: 60px;
    }
    .input-group-text {
        border-color: #EEEEEE;
        background: transparent;
        border-left: 0;
        height: 60px;
        border-radius: 3.125rem;
        i {
            font-size: 1.8rem;
        }
    }
}

.contact-bx {
    background-color: var(--rgba-primary-2);
    border-width: 2px;
    text-align: center;
    .card-header {
        position: absolute;
        right: 0;
        top: 0;
    }
    .image-bx {
        display: inline-block;
        position: relative;
        margin-bottom: 10px;
        img {
            width: 120px;
            height: 120px;
        }
        span {
            &.active {
                height: 24px;
                width: 24px;
                position: absolute;
                bottom: 3px;
                right: 3px;
                border-radius: 2rem;
                background: $success;
                border: 4px solid #fff;
            }
        }
    }
    &:hover {
        box-shadow: rgba(136, 108, 192, 0.2) 0px 7px 29px 0px;
    }
}

.user-meta-info {
    ul {
        display: flex;
        justify-content: center;
        li {
            a {
                margin: 0 10px;
                height: 48px;
                width: 48px;
                border-radius: 50px;
                background: var(--rgba-primary-1);
                color: var(--primary);
                line-height: 48px;
                display: block;
                font-size: 20px;
                transition: all 0.5s;
                text-align: center;
                &:hover {
                    background: var(--primary);
                    color: $white;
                }
            }
        }
    }
}